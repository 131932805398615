import React, { useEffect } from "react";
import { useTable, usePagination } from "react-table";
import createPagination from "../Hooks/usePagination";
import { MdCompareArrows } from "react-icons/md";

export default ({
    columns,
    data,
    updatePagination,
    loading,
    paginationState,
    pageCount: controlledPageCount,
    totalCount,
    sortable,
    hidePagination,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        previousPage,
        nextPage,
        pageCount,
        canNextPage,
        canPreviousPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            pageCount: controlledPageCount,
            initialState: {
                pageIndex: 0,
            },
        },
        usePagination
    );

    useEffect(() => {
        if (updatePagination) {
            updatePagination((prev) => ({
                ...prev,
                // limit: pageSize,
                page: pageIndex + 1,
            }));
        }
    }, [updatePagination, pageIndex, pageSize]);

    const { pagination } = createPagination({
        numberOfButtons: 2,
        currentPage: paginationState ? paginationState.page : pageIndex + 1,
        totalPages: paginationState
            ? paginationState.totalPages
                ? paginationState.totalPages
                : controlledPageCount
            : controlledPageCount,
    });

    return (
        <div className="table-wrapper block max-w-full overflow-y-hidden ">
            <table className="bg-white w-full" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    className="p-4 bg-gray-800 text-white border-t font-semibold text-xs min-w-ten"
                                    {...column.getHeaderProps()}
                                >
                                    <div
                                        className={`flex items-center justify-center`}
                                    >
                                        <div className="whitespace-no-wrap">
                                            {column.render("Header")}
                                        </div>
                                        {sortable && (
                                            <div className="cursor-pointer ml-2">
                                                {sortable.includes(
                                                    column.id
                                                ) && (
                                                    <MdCompareArrows
                                                        onClick={() => {
                                                            updatePagination(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    by:
                                                                        column.id,
                                                                    dir:
                                                                        prev.dir ===
                                                                        "asc"
                                                                            ? "desc"
                                                                            : "asc",
                                                                    page: 1
                                                                })
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                className={
                                    row.original.status === "inactive"
                                        ? "bg-red-700 text-white"
                                        : row.original.status === "converted"
                                        ? "bg-yellow-300 text-black"
                                        : row.original.status === "signedLo"
                                        ? "bg-green-300 text-black"
                                        : row.original.status === "suspended"
                                        ? "bg-red-700 text-white"
                                        : row.original.status === "void"
                                        ? "bg-purple-500 text-white"
                                        : row.original.calculatePoints === "void"
                                        ? "bg-red-700 text-white"
                                        : ""
                                }
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            className="text-center p-4 text-sm border-t"
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {!hidePagination && (
                <div className="p-4 bg-cool-gray border-t font-semibold text-lynch text-xs">
                    <ul className="flex justify-end pr-5">
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            First
                        </li>
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => previousPage()}
                        >
                            Previous
                        </li>
                        {pagination.map((page) => (
                            <li
                                className={`p-2 px-4 cursor-pointer ${
                                    page === pageIndex + 1
                                        ? "bg-darkblue text-white"
                                        : "text-xs"
                                }`}
                                key={page}
                                onClick={() => gotoPage(page - 1)}
                            >
                                {page}
                            </li>
                        ))}
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => nextPage()}
                        >
                            Next
                        </li>
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            Last
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};
