import { gql } from "apollo-boost";

export default {
    LOGIN: gql`
        query Login($username: String!, $password: String!) {
            adminLogin(input: { username: $username, password: $password }) {
                userId
                token
                name
                type
            }
        }
    `,
    FETCH_RANKINGS: gql`
        query FetchRankings {
            fetchRankings {
                _id
                name
            }
        }
    `,
    FETCH_PROJECTS: gql`
        query FetchPackages(
            $limit: Int!
            $page: Int!
            $state: String
            $package: String
            $country: String
            $area: String
        ) {
            fetchPackages(
                filters: { state: $state, package: $package, country: $country, area: $area }
                pagination: { limit: $limit, page: $page }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    name
                    cnName
                    image
                    itinerary
                    video
                    assemblyTime
                    meetupPoint
                    tourguideName
                    tourguideChineseName
                    tourguidePhone
                    handcarryWeight
                    luggageWeight
                    deposit
                    savings
                    packageCode
                    status
                    state
                    country
                    area
                    attributes {
                        _id
                        attribute
                        value
                    }
                }
            }
        }
    `,
    FETCH_CASES: gql`
        query FetchTours(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $package: ID
            $date: String
            $dateFrom: String
            $dateTo: String
            $status: String
            $country: String
            $state: String
            $unitNumber: String
        ) {
            fetchTours(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: {
                    package: $package
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                    unitNumber: $unitNumber
                    country: $country
                    state: $state
                }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    _id
                    package {
                        _id
                        name
                    }
                    createdBy {
                        _id
                        name
                    }
                    childPrice
                    adultPrice
                    infantPrice
                    childNoBedPrice
                    singlePrice
                    tourCode
                    createdAt
                    tourDate
                    status
                    flights {
                        _id
                        type
                        airway
                        code
                        startCity
                        endCity
                        startTime
                        endTime
                        transitStartCity
                        transitEndCity
                        transitStartTime
                        transitEndTime
                    }
                    state
                    country
                    slots
                    breakdown {
                        ground
                        groundPackage
                        supp
                        airticket
                        tipping
                        insurance
                        visa
                        singleGroundPackage
                        singleSupp
                        singleAirticket
                        singleTipping
                        singleInsurance
                        singleVisa
                        childGroundPackage
                        childSupp
                        childAirticket
                        childTipping
                        childInsurance
                        childVisa
                        childNoBedGroundPackage
                        childNoBedSupp
                        childNoBedAirticket
                        childNoBedTipping
                        childNoBedInsurance
                        childNoBedVisa
                    }
                    amenities {
                        insurance
                        wifi
                        group
                        tax
                        checkin
                        tourleader
                        hotel
                        meals
                        gratuties
                        fullpayment
                        accessible
                        facilities
                        pool
                        driver
                        allin
                    }
                }
            }
        }
    `,
    FETCH_ORDERS: gql`
        query FetchOrders(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $country: String
            $state: String
            $status: String
        ) {
            fetchOrders(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: { country: $country, state: $state, status: $status }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    _id
                    members {
                        designation
                        name
                        dob
                        passport
                        phone
                        remark
                    }
                    tour {
                        _id
                        package {
                            _id
                            name
                        }
                        tourCode
                        tourDate
                        status
                    }
                    slots
                    childPax
                    adultPax
                    childnobedPax
                    singlePax
                    status
                    price
                    discount
                    createdBy {
                        _id
                        name
                    }
                    createdAt
                    receipt
                    phone
                    email
                    orderId
                }
            }
        }
    `,
    FETCH_UNDERGROUND_CASES: gql`
        query FetchUndergroundTours(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $package: ID
            $user: ID
            $date: String
            $dateFrom: String
            $dateTo: String
            $status: String
        ) {
            fetchUndergroundTours(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: {
                    user: $user
                    package: $package
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                }
            ) {
                totals {
                    tours
                    spaPrice
                    netPrice
                    totalNetGdv
                    totalCommission
                    totalCommissionPercentage
                    totalPrimeLeaderComm
                    totalLeaderComm
                    totalPtpReferralComm
                    totalPtp2ReferralComm
                    totalPtp1ReferralComm
                    totalGroupNetGdv
                }
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    _id
                    coordinators {
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                    }
                    coordinatorCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                        coordinator
                    }
                    members {
                        _id
                        commission
                        newCommission
                        member {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        memberCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    sponsorCommissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        referralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    leaderCommissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        leaderReferralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    ptpCommissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        ptpReferralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    ptp2Commissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        ptp2ReferralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    ptp1Commissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        ptp1ReferralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    image
                    salesKit
                    spaPrice
                    rebate
                    fees
                    cashback
                    netPrice
                    status
                    bookingDate
                    unitNumber
                    purchaser {
                        purchaserName
                        purchaserName2
                        purchaserName3
                        purchaserName4
                        purchaserName5
                        purchaserIc
                        purchaserIc2
                        purchaserIc3
                        purchaserIc4
                        purchaserIc5
                        purchaserContact
                        purchaserContact2
                        purchaserContact3
                        purchaserContact4
                        purchaserContact5
                    }
                    commissionStatus
                    createdBy {
                        _id
                        name
                    }
                    package {
                        _id
                        name
                        coordinatorPercentage
                        leaderPercentage
                        preleaderPercentage
                    }
                }
            }
        }
    `,
    FETCH_CASES_REPORT_NO_521: gql`
        query FetchToursNo521(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $package: ID
            $user: ID
            $date: String
            $dateFrom: String
            $dateTo: String
            $status: String
        ) {
            fetchToursNo521(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: {
                    user: $user
                    package: $package
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                }
            ) {
                totals {
                    tours
                    spaPrice
                    netPrice
                    totalNetGdv
                    totalCommission
                    totalCommissionPercentage
                    totalPrimeLeaderComm
                    totalLeaderComm
                    totalPtpReferralComm
                    totalPtp2ReferralComm
                    totalPtp1ReferralComm
                    totalGroupNetGdv
                }
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    _id
                    coordinators {
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                    }
                    coordinatorCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                        coordinator
                    }
                    members {
                        _id
                        commission
                        newCommission
                        member {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        memberCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    sponsorCommissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        referralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    leaderCommissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        leaderReferralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    image
                    salesKit
                    spaPrice
                    rebate
                    fees
                    cashback
                    netPrice
                    status
                    bookingDate
                    unitNumber
                    purchaser {
                        purchaserName
                        purchaserName2
                        purchaserName3
                        purchaserName4
                        purchaserName5
                        purchaserIc
                        purchaserIc2
                        purchaserIc3
                        purchaserIc4
                        purchaserIc5
                        purchaserContact
                        purchaserContact2
                        purchaserContact3
                        purchaserContact4
                        purchaserContact5
                    }
                    commissionStatus
                    createdBy {
                        _id
                        name
                    }
                    package {
                        _id
                        name
                        coordinatorPercentage
                        leaderPercentage
                        preleaderPercentage
                    }
                }
            }
        }
    `,
    FETCH_QUALIFIED_MEMBERS: gql`
        query FetchQualifiedMembers(
            $package: ID
            $date: String
            $dateFrom: String
            $dateTo: String
            $status: String
            $unitNumber: String
        ) {
            fetchQualifiedMembers(
                filter: {
                    package: $package
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                    unitNumber: $unitNumber
                }
            ) {
                data {
                    member {
                        _id
                        name
                        status
                    }
                    totalNetGDV
                }
            }
        }
    `,
    FETCH_SALES_RANKING_PERCENTAGE: gql`
        query FetchSalesRankingPercentage(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $package: ID
            $dateFrom: String
            $dateTo: String
            $status: String
            $user: ID
        ) {
            fetchSalesRankingPercentage(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: {
                    package: $package
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                    user: $user
                }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    member {
                        _id
                        name
                    }
                    commission
                    value
                }
            }
        }
    `,
    FETCH_CASES_SALES_RANKING: gql`
        query FetchToursSalesRanking(
            $limit: Int!
            $page: Int!
            $package: ID
            $by: String
            $dir: String
            $date: String
            $dateFrom: String
            $dateTo: String
            $rank: ID
            $state: ID
            $status: String
            $gender: String
        ) {
            fetchToursSalesRanking(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: {
                    package: $package
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                    rank: $rank
                    state: $state
                    gender: $gender
                }
            ) {
                pagination {
                    documents
                    by
                    dir
                    page
                    limit
                    totalPages
                }
                data {
                    ranking
                    name
                    user
                    commission
                    groupCommission
                }
            }
        }
    `,
    FETCH_CASES_QUEENDOM: gql`
        query FetchToursQueendom(
            $limit: Int!
            $page: Int!
            $package: ID
            $by: String
            $dir: String
            $date: String
            $dateFrom: String
            $dateTo: String
            $rank: ID
            $state: ID
            $status: String
            $gender: String
        ) {
            fetchToursQueendom(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: {
                    package: $package
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                    rank: $rank
                    state: $state
                    gender: $gender
                }
            ) {
                pagination {
                    documents
                    by
                    dir
                    page
                    limit
                    totalPages
                }
                data {
                    ranking
                    name
                    user
                    commission
                }
            }
        }
    `,
    FETCH_CASES_AGENT: gql`
        query FetchToursAgent(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $package: ID
            $user: ID
            $date: String
            $dateFrom: String
            $dateTo: String
            $status: String
        ) {
            fetchToursAgent(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: {
                    user: $user
                    package: $package
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                }
            ) {
                totals {
                    tours
                    spaPrice
                    netPrice
                    totalNetGdv
                    totalCommission
                    totalCommissionPercentage
                    totalPrimeLeaderComm
                    totalLeaderComm
                }
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    _id
                    coordinators {
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                    }
                    members {
                        _id
                        commission
                        member {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                    }
                    sponsorCommissions {
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                    }
                    leaderCommissions {
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                    }
                    ptpCommissions {
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                    }
                    image
                    salesKit
                    spaPrice
                    rebate
                    fees
                    cashback
                    netPrice
                    status
                    bookingDate
                    unitNumber
                    purchaser {
                        purchaserName
                        purchaserName2
                        purchaserName3
                        purchaserName4
                        purchaserName5
                        purchaserIc
                        purchaserIc2
                        purchaserIc3
                        purchaserIc4
                        purchaserIc5
                        purchaserContact
                        purchaserContact2
                        purchaserContact3
                        purchaserContact4
                        purchaserContact5
                    }
                    createdBy {
                        _id
                        name
                    }
                    package {
                        _id
                        name
                        coordinatorPercentage
                        leaderPercentage
                        preleaderPercentage
                    }
                }
            }
        }
    `,
    FETCH_CASES_REPORT: gql`
        query FetchTours(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $package: ID
            $user: ID
            $date: String
            $dateFrom: String
            $dateTo: String
            $status: String
        ) {
            fetchTours(
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
                filter: {
                    user: $user
                    package: $package
                    date: $date
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    status: $status
                }
            ) {
                totals {
                    tours
                    spaPrice
                    netPrice
                    totalNetGdv
                    totalCommission
                    totalCommissionPercentage
                    totalPrimeLeaderComm
                    totalLeaderComm
                    totalPtpReferralComm
                    totalPtp2ReferralComm
                    totalPtp1ReferralComm
                    totalGroupNetGdv
                    totalRemainingComm
                    totalLeaderRemainingComm
                    totalPrimeLeaderRemainingComm
                    totalPTP5RemainingComm
                    totalPTP2RemainingComm
                    totalPTP1RemainingComm
                }
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    _id
                    coordinators {
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                    }
                    coordinatorCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                        coordinator
                    }
                    members {
                        _id
                        commission
                        newCommission
                        member {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        memberCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    sponsorCommissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        referralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    leaderCommissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        leaderReferralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    ptpCommissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        ptpReferralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    ptp2Commissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        ptp2ReferralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    ptp1Commissions {
                        _id
                        user {
                            _id
                            name
                            ranking {
                                _id
                                name
                            }
                        }
                        commission
                        amount
                        ptp1ReferralCommStatus {
                            fastComm
                            firstBatchComm
                            secondBatchComm
                            fullClaimComm
                            revisedComm
                        }
                    }
                    image
                    salesKit
                    spaPrice
                    rebate
                    fees
                    cashback
                    netPrice
                    status
                    bookingDate
                    unitNumber
                    purchaser {
                        purchaserName
                        purchaserName2
                        purchaserName3
                        purchaserName4
                        purchaserName5
                        purchaserIc
                        purchaserIc2
                        purchaserIc3
                        purchaserIc4
                        purchaserIc5
                        purchaserContact
                        purchaserContact2
                        purchaserContact3
                        purchaserContact4
                        purchaserContact5
                    }
                    commissionStatus
                    createdBy {
                        _id
                        name
                    }
                    package {
                        _id
                        name
                        coordinatorPercentage
                        leaderPercentage
                        preleaderPercentage
                    }
                }
            }
        }
    `,
    UPLOAD_GALLERY: gql`
        mutation UploadGallery($img: String!, $packageId: String!) {
            uploadGallery(input: { img: $img, packageId: $packageId }) {
                _id
            }
        }
    `,
    UPLOAD_PAYMENT: gql`
        mutation UploadPayment($img: String!, $packageId: String!) {
            uploadPayment(input: { img: $img, packageId: $packageId }) {
                _id
            }
        }
    `,
    CREATE_PROJECT: gql`
        mutation createPackage(
            $name: String!
            $cnName: String!
            $image: String!
            $itinerary: String
            $video: String
            $assemblyTime: String
            $meetupPoint: String
            $tourguideName: String
            $tourguideChineseName: String
            $tourguidePhone: String
            $handcarryWeight: String
            $luggageWeight: String
            $deposit: Float!
            $savings: Float!
            $state: String
            $country: String
            $area: String
        ) {
            createPackage(
                input: {
                    name: $name
                    cnName: $cnName
                    image: $image
                    itinerary: $itinerary
                    video: $video
                    assemblyTime: $assemblyTime
                    meetupPoint: $meetupPoint
                    tourguideName: $tourguideName
                    tourguideChineseName: $tourguideChineseName
                    tourguidePhone: $tourguidePhone
                    handcarryWeight: $handcarryWeight
                    luggageWeight: $luggageWeight
                    deposit: $deposit
                    savings: $savings
                    state: $state
                    country: $country
                    area: $area
                }
            ) {
                name
            }
        }
    `,

    EDIT_PROJECT: gql`
        mutation editPackage(
            $_id: ID!
            $name: String!
            $cnName: String!
            $image: String!
            $itinerary: String
            $video: String
            $assemblyTime: String
            $meetupPoint: String
            $tourguideName: String
            $tourguideChineseName: String
            $tourguidePhone: String
            $handcarryWeight: String
            $luggageWeight: String
            $deposit: Float!
            $savings: Float!
        ) {
            updatePackage(
                input: {
                    _id: $_id
                    name: $name
                    cnName: $cnName
                    image: $image
                    itinerary: $itinerary
                    video: $video
                    assemblyTime: $assemblyTime
                    meetupPoint: $meetupPoint
                    tourguideName: $tourguideName
                    tourguideChineseName: $tourguideChineseName
                    tourguidePhone: $tourguidePhone
                    handcarryWeight: $handcarryWeight
                    luggageWeight: $luggageWeight
                    deposit: $deposit
                    savings: $savings
                }
            ) {
                _id
            }
        }
    `,
    EDIT_PROJECT_COMMISSION: gql`
        mutation editPackageCommission(
            $_id: ID!
            $coordinatorPercentage: Float!
            $preleaderPercentage: Float!
            $leaderPercentage: Float!
        ) {
            updatePackage(
                input: {
                    _id: $_id
                    coordinatorPercentage: $coordinatorPercentage
                    preleaderPercentage: $preleaderPercentage
                    leaderPercentage: $leaderPercentage
                }
            ) {
                _id
            }
        }
    `,
    EDIT_COORDINATOR_END_DATE: gql`
        mutation editCoordinatorEndDate(
            $_id: ID!
            $packageId: ID!
            $startDate: String
            $endDate: String!
        ) {
            editCoordinatorEndDate(
                input: {
                    _id: $_id
                    packageId: $packageId
                    endDate: $endDate
                    startDate: $startDate
                }
            ) {
                coordinators {
                    _id
                    user {
                        _id
                        name
                    }
                    startDate
                    endDate
                }
            }
        }
    `,
    ADD_COORDINATOR_TO_PROJECT: gql`
        mutation AddCoordinatorToPackage(
            $packageId: ID!
            $memberId: ID!
            $startDate: String!
            $endDate: String
        ) {
            addCoordinatorToPackage(
                input: {
                    packageId: $packageId
                    memberId: $memberId
                    startDate: $startDate
                    endDate: $endDate
                }
            ) {
                _id
                coordinators {
                    _id
                    user {
                        _id
                        name
                    }
                    startDate
                    endDate
                }
            }
        }
    `,

    REMOVE_COORDINATOR_FROM_PROJECT: gql`
        mutation RemoveCoordinatorFromPackage(
            $packageId: ID!
            $coordinatorId: ID!
        ) {
            removeCoordinatorFromPackage(
                input: { packageId: $packageId, coordinatorId: $coordinatorId }
            ) {
                _id
                coordinators {
                    _id
                    user {
                        _id
                        name
                    }
                    startDate
                    endDate
                }
            }
        }
    `,
    ADD_PROJECT_ATTRIBUTE: gql`
        mutation AddPackageAttribute(
            $packageId: ID!
            $attribute: String!
            $value: String!
        ) {
            addPackageAttribute(
                input: {
                    packageId: $packageId
                    attribute: $attribute
                    value: $value
                }
            ) {
                _id
                attributes {
                    _id
                    attribute
                    value
                }
            }
        }
    `,
    REMOVE_ATTRIBUTE_FROM_PROJECT: gql`
        mutation RemoveAttributeFromPackage(
            $packageId: ID!
            $attributeId: ID!
        ) {
            removeAttributeFromPackage(
                input: { packageId: $packageId, attributeId: $attributeId }
            ) {
                _id
                attributes {
                    _id
                    attribute
                    value
                }
            }
        }
    `,
    CREATE_CASE: gql`
        mutation createTour(
            $package: ID!
            $tourCode: String!
            $childPrice: Float
            $tourDate: String
            $adultPrice: Float
            $childNoBedPrice: Float
            $singlePrice: Float
            $infantPrice: Float
            $slots: Float
        ) {
            createTour(
                input: {
                    package: $package
                    tourCode: $tourCode
                    childPrice: $childPrice
                    adultPrice: $adultPrice
                    childNoBedPrice: $childNoBedPrice
                    singlePrice: $singlePrice
                    infantPrice: $infantPrice
                    tourDate: $tourDate
                    slots: $slots
                }
            ) {
                _id
            }
        }
    `,
    EDIT_CASE: gql`
        mutation updateTour(
            $_id: ID!
            $package: ID!
            $tourCode: String!
            $tourDate: String
            $childPrice: Float
            $adultPrice: Float
            $infantPrice: Float
            $childNoBedPrice: Float
            $singlePrice: Float
            $slots: Float
        ) {
            updateTour(
                input: {
                    _id: $_id
                    package: $package
                    tourCode: $tourCode
                    childPrice: $childPrice
                    tourDate: $tourDate
                    adultPrice: $adultPrice
                    childNoBedPrice: $childNoBedPrice
                    singlePrice: $singlePrice
                    infantPrice: $infantPrice
                    slots: $slots
                }
            ) {
                _id
            }
        }
    `,
    FETCH_PIPELINE: gql`
        query FetchPipeline(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $dateFrom: String
            $dateTo: String
            $package: ID
            $state: ID
            $salesDirector: ID
        ) {
            fetchPipeline(
                filter: {
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                    package: $package
                    salesDirector: $salesDirector
                    state: $state
                }
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
            ) {
                data {
                    package
                    state
                    pendingTours
                    bookedTours
                    cancelledTours
                    rejectedTours
                    allTours
                    conversionRate
                    pendingRate
                    cancellationRate
                    rejectionRate
                }
            }
        }
    `,
    FETCH_PERFORMANCE: gql`
        query FetchPerformance(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $package: String
            $user: String
            $salesDirector: ID
            $state: ID
        ) {
            fetchPerformance(
                filter: {
                    package: $package
                    user: $user
                    salesDirector: $salesDirector
                    state: $state
                }
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
            ) {
                data {
                    year
                    pendingTours
                    bookedTours
                    cancelledTours
                    rejectedTours
                    convertedTours
                    allTours
                    conversionRate
                    cancellationRate
                    rejectionRate
                    bookRate
                    pendingRate
                    allRate
                }
            }
        }
    `,
    FETCH_MONTHLY_PERFORMANCE: gql`
        query FetchMonthlyPerformance(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $package: String
            $user: String
            $state: ID
            $salesDirector: ID
        ) {
            fetchMonthlyPerformance(
                filter: {
                    package: $package
                    user: $user
                    state: $state
                    salesDirector: $salesDirector
                }
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
            ) {
                data {
                    month
                    year
                    pendingTours
                    bookedTours
                    cancelledTours
                    rejectedTours
                    convertedTours
                    allTours
                    conversionRate
                    cancellationRate
                    bookRate
                    pendingRate
                    rejectionRate
                    allRate
                }
            }
        }
    `,
    FETCH_PRIME_PERFORMANCE: gql`
        query FetchPrimePerformance(
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $package: String
            $dateTo: String
            $dateFrom: String
            $user: String
            $state: ID
            $salesDirector: ID
        ) {
            fetchPrimePerformance(
                filter: {
                    package: $package
                    dateTo: $dateTo
                    dateFrom: $dateFrom
                    user: $user
                    state: $state
                    salesDirector: $salesDirector
                }
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
            ) {
                data {
                    user
                    pendingTours
                    bookedTours
                    cancelledTours
                    rejectedTours
                    allTours
                    conversionRate
                    cancellationRate
                    rejectionRate
                    bookRate
                    pendingRate
                    status
                }
            }
        }
    `,
    ADD_FLIGHT_TO_TOUR: gql`
        mutation AddFlightToTour(
            $tourId: ID!
            $airway: String!
            $code: String!
            $startCity: String!
            $endCity: String!
            $startTime: String!
            $endTime: String!
            $transitStartCity: String!
            $transitEndCity: String!
            $transitStartTime: String!
            $transitEndTime: String!
            $type: FlightType!
        ) {
            addFlightToTour(
                input: {
                    tourId: $tourId
                    airway: $airway
                    code: $code
                    startCity: $startCity
                    endCity: $endCity
                    startTime: $startTime
                    endTime: $endTime
                    transitStartCity: $transitStartCity
                    transitEndCity: $transitEndCity
                    transitStartTime: $transitStartTime
                    transitEndTime: $transitEndTime
                    type: $type
                }
            ) {
                _id
                flights {
                    _id
                    airway
                    code
                    startCity
                    endCity
                    startTime
                    endTime
                    type
                }
            }
        }
    `,
    ADD_MEMBER_TO_ORDER: gql`
        mutation AddMemberToOrder(
            $orderId: ID!
            $type: String!
            $name: String!
            $designation: String!
            $passport: String!
            $phone: String!
            $remark: String
            $dob: String!
        ) {
            addMemberToOrder(
                input: {
                    orderId: $orderId
                    type: $type
                    name: $name
                    designation: $designation
                    passport: $passport
                    phone: $phone
                    remark: $remark
                    dob: $dob
                }
            ) {
                _id
                members {
                    name
                    designation
                    passport
                    phone
                    remark
                    dob
                }
            }
        }
    `,

    REMOVE_FLIGHT_FROM_TOUR: gql`
        mutation RemoveFlightFromTour($tourId: ID!, $flightId: ID!) {
            removeFlightFromTour(
                input: { tourId: $tourId, flightId: $flightId }
            ) {
                _id
                flights {
                    _id
                    airway
                    code
                    startCity
                    endCity
                    startTime
                    endTime
                    type
                }
            }
        }
    `,

    REMOVE_MEMBER_FROM_ORDER: gql`
        mutation RemoveMemberFromOrder($orderId: ID!, $passport: String!) {
            removeMemberFromOrder(
                input: { orderId: $orderId, passport: $passport }
            ) {
                _id
                members {
                    name
                    designation
                    passport
                    phone
                    remark
                    dob
                    type
                }
            }
        }
    `,
    EDIT_ORDER: gql`
        mutation updateOrder(
            $_id: ID!
            $childPax: Int
            $adultPax: Int
            $childnobedPax: Int
            $singlePax: Int
            $price: Float
            $discount: Float
        ) {
            updateOrder(
                input: {
                    _id: $_id
                    childPax: $childPax
                    adultPax: $adultPax
                    childnobedPax: $childnobedPax
                    singlePax: $singlePax
                    price: $price
                    discount: $discount
                }
            ) {
                _id
            }
        }
    `,
    EDIT_COMMISSION: gql`
        mutation EditCommission($memberId: ID!, $commission: Float!) {
            editCommission(
                input: { memberId: $memberId, commission: $commission }
            ) {
                members {
                    _id
                    member {
                        _id
                        name
                        ranking {
                            _id
                            name
                        }
                    }
                    newCommission
                    commission
                    newCommission
                    commission
                    memberCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                    referralCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                    leaderReferralCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                    ptpReferralCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                }
            }
        }
    `,

    EDIT_COMMISSION_STATUS: gql`
        mutation EditCommissionStatus(
            $memberId: ID
            $fastComm: Float
            $firstBatchComm: Float
            $secondBatchComm: Float
            $fullClaimComm: Float
            $revisedComm: Float
            $type: String!
            $tourId: ID!
        ) {
            editCommissionStatus(
                input: {
                    tourId: $tourId
                    memberId: $memberId
                    fastComm: $fastComm
                    firstBatchComm: $firstBatchComm
                    secondBatchComm: $secondBatchComm
                    fullClaimComm: $fullClaimComm
                    revisedComm: $revisedComm
                    type: $type
                }
            ) {
                members {
                    _id
                    commission
                    newCommission
                    member {
                        _id
                        name
                        ranking {
                            _id
                            name
                        }
                    }
                    memberCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                }
                sponsorCommissions {
                    _id
                    user {
                        _id
                        name
                        ranking {
                            _id
                            name
                        }
                    }
                    commission
                    amount
                    referralCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                }
                leaderCommissions {
                    _id
                    user {
                        _id
                        name
                        ranking {
                            _id
                            name
                        }
                    }
                    commission
                    amount
                    leaderReferralCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                }
                ptpCommissions {
                    _id
                    user {
                        _id
                        name
                        ranking {
                            _id
                            name
                        }
                    }
                    commission
                    amount
                    ptpReferralCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                }
                ptp2Commissions {
                    _id
                    user {
                        _id
                        name
                        ranking {
                            _id
                            name
                        }
                    }
                    commission
                    amount
                    ptp2ReferralCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                }
                ptp1Commissions {
                    _id
                    user {
                        _id
                        name
                        ranking {
                            _id
                            name
                        }
                    }
                    commission
                    amount
                    ptp1ReferralCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                }
                members {
                    _id
                    member {
                        _id
                        name
                        ranking {
                            _id
                            name
                        }
                    }
                    newCommission
                    commission
                    memberCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                    referralCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                    leaderReferralCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                    ptpReferralCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                    coordinatorCommStatus {
                        fastComm
                        firstBatchComm
                        secondBatchComm
                        fullClaimComm
                        revisedComm
                    }
                }
            }
        }
    `,
    EDIT_COORDINATOR_COMMISSION_STATUS: gql`
        mutation EditCoordinatorCommissionStatus(
            $memberId: ID!
            $fastComm: Float
            $firstBatchComm: Float
            $secondBatchComm: Float
            $fullClaimComm: Float
            $revisedComm: Float
            $type: String!
            $tour: ID!
        ) {
            editCoordinatorCommissionStatus(
                input: {
                    memberId: $memberId
                    fastComm: $fastComm
                    firstBatchComm: $firstBatchComm
                    secondBatchComm: $secondBatchComm
                    fullClaimComm: $fullClaimComm
                    revisedComm: $revisedComm
                    type: $type
                    tour: $tour
                }
            ) {
                coordinatorCommStatus {
                    fastComm
                    firstBatchComm
                    secondBatchComm
                    fullClaimComm
                    revisedComm
                    coordinator
                }
            }
        }
    `,

    EDIT_PURCHASER: gql`
        mutation EditPurchaser(
            $tourId: ID!
            $purchaserName: String!
            $purchaserName2: String
            $purchaserName3: String
            $purchaserName4: String
            $purchaserName5: String
            $purchaserIc: String!
            $purchaserIc2: String
            $purchaserIc3: String
            $purchaserIc4: String
            $purchaserIc5: String
            $purchaserContact: String!
            $purchaserContact2: String
            $purchaserContact3: String
            $purchaserContact4: String
            $purchaserContact5: String
        ) {
            editPurchaser(
                input: {
                    tourId: $tourId
                    purchaserName: $purchaserName
                    purchaserName2: $purchaserName2
                    purchaserName3: $purchaserName3
                    purchaserName4: $purchaserName4
                    purchaserName5: $purchaserName5
                    purchaserIc: $purchaserIc
                    purchaserIc2: $purchaserIc2
                    purchaserIc3: $purchaserIc3
                    purchaserIc4: $purchaserIc4
                    purchaserIc5: $purchaserIc5
                    purchaserContact: $purchaserContact
                    purchaserContact2: $purchaserContact2
                    purchaserContact3: $purchaserContact3
                    purchaserContact4: $purchaserContact4
                    purchaserContact5: $purchaserContact5
                }
            ) {
                _id
            }
        }
    `,
    FETCH_USERS: gql`
        query FetchUsers(
            $name: String
            $code: String
            $limit: Int!
            $page: Int!
            $by: String
            $dir: String
            $status: String
            $ranking: String
            $state: String
            $type: String
            $memberclub: String
        ) {
            fetchUsers(
                filters: {
                    name: $name
                    code: $code
                    status: $status
                    ranking: $ranking
                    state: $state
                    type: $type
                    memberclub: $memberclub
                }
                pagination: {
                    limit: $limit
                    page: $page
                    sort: { by: $by, dir: $dir }
                }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                    by
                    dir
                }
                data {
                    _id
                    name
                    username
                    phone
                    email
                    address
                    city
                    state
                    postal
                    country
                    status
                    createdAt
                    createdBy {
                        _id
                        name
                    }
                }
            }
        }
    `,
    EDIT_USER_DETAILS: gql`
        mutation EditUserDetails(
            $userId: ID!
            $address: String
            $city: String
            $state: String
            $postal: String
            $country: String
        ) {
            editUserDetails(
                input: {
                    userId: $userId
                    address: $address
                    city: $city
                    state: $state
                    postal: $postal
                    country: $country
                }
            ) {
                _id
            }
        }
    `,
    EDIT_USER: gql`
        mutation UpdateUser(
            $userId: ID!
            $image: String
            $name: String
            $ranking: ID
            $password: String
            $phone: String
            $bankDetails: String
            $code: String
            $username: String
            $email: String
        ) {
            updateUser(
                userId: $userId
                input: {
                    image: $image
                    name: $name
                    ranking: $ranking
                    password: $password
                    phone: $phone
                    bankDetails: $bankDetails
                    code: $code
                    email: $email
                    username: $username
                }
            ) {
                _id
            }
        }
    `,
    GENERATE_IK_SIGNATURE: gql`
        query {
            generateImageKitSignature {
                signature
                token
                expire
            }
        }
    `,
    UPDATE_DREAMPOINTS: gql`
        mutation UpdateDreamPoints($userId: ID!, $value: Int!) {
            updateDreamPoints(userId: $userId, value: $value) {
                _id
            }
        }
    `,
    UPDATE_CASE_STATUS: gql`
        mutation UpdateTourStatus($id: ID!, $status: OrderStatusTypeEnum!) {
            updateTourStatus(input: { id: $id, status: $status }) {
                _id
            }
        }
    `,
    UPDATE_ORDER_STATUS: gql`
        mutation UpdateOrderStatus($id: ID!, $status: OrderStatusTypeEnum!) {
            updateOrderStatus(input: { id: $id, status: $status }) {
                _id
                orderId
                members {
                    name
                    designation
                    passport
                    phone
                    remark
                    dob
                }
                childPax
                adultPax
                childnobedPax
                singlePax
                slots
                createdAt
                price
                phone
                email
                discount
                tour {
                    _id
                    tourCode
                    tourDate
                    country
                    state
                    package {
                        _id
                        name
                    }
                    flights {
                        _id
                        airway
                        code
                        startCity
                        endCity
                        startTime
                        endTime
                        type
                    }
                }
            }
        }
    `,

    UPDATE_USER_STATUS: gql`
        mutation UpdateUserStatus(
            $id: ID!
            $status: StatusTypeEnum!
            $state: String
            $joinDate: String
            $resignDate: String
        ) {
            updateUserStatus(
                input: {
                    id: $id
                    status: $status
                    state: $state
                    joinDate: $joinDate
                    resignDate: $resignDate
                }
            ) {
                _id
            }
        }
    `,
    UPDATE_SECOND_REFERRAL: gql`
        mutation UpdateSecondReferral($id: ID!, $secondReferral: ID!) {
            updateSecondReferral(
                input: { id: $id, secondReferral: $secondReferral }
            ) {
                _id
            }
        }
    `,
    ADD_CP58_TO_USER: gql`
        mutation AddCp58ToUser($id: ID!, $cp58: String!) {
            addCp58ToUser(input: { id: $id, cp58: $cp58 }) {
                _id
            }
        }
    `,
    UPDATE_USER_RANKING: gql`
        mutation UpdateUserRanking(
            $id: ID!
            $ranking: RankingTypeEnum!
            $preleaderDate: String
            $leaderDate: String
            $omcDate: String
            $pmcDate: String
            $emcDate: String
            $smcDate: String
        ) {
            updateUserRanking(
                input: {
                    id: $id
                    ranking: $ranking
                    preleaderDate: $preleaderDate
                    leaderDate: $leaderDate
                    omcDate: $omcDate
                    smcDate: $smcDate
                    emcDate: $emcDate
                    pmcDate: $pmcDate
                }
            ) {
                _id
            }
        }
    `,
    UPDATE_COMMISSION_STATUS: gql`
        mutation UpdateCommissionStatus($id: ID!, $status: StatusTypeEnum!) {
            updateCommissionStatus(input: { id: $id, status: $status }) {
                _id
                status
            }
        }
    `,
    UPDATE_BREAKDOWN: gql`
        mutation UpdateBreakdown(
            $_id: ID!
            $groundPackage: Float!
            $supp: Float!
            $airticket: Float!
            $tipping: Float!
            $insurance: Float!
            $visa: Float!
            $singleGroundPackage: Float!
            $singleSupp: Float!
            $singleAirticket: Float!
            $singleTipping: Float!
            $singleInsurance: Float!
            $singleVisa: Float!
            $childGroundPackage: Float!
            $childSupp: Float!
            $childAirticket: Float!
            $childTipping: Float!
            $childInsurance: Float!
            $childVisa: Float!
            $childNoBedGroundPackage: Float!
            $childNoBedSupp: Float!
            $childNoBedAirticket: Float!
            $childNoBedTipping: Float!
            $childNoBedInsurance: Float!
            $childNoBedVisa: Float!
            $ground: Float!
        ) {
            updateBreakdown(
                input: {
                    _id: $_id
                    groundPackage: $groundPackage
                    supp: $supp
                    airticket: $airticket
                    tipping: $tipping
                    insurance: $insurance
                    visa: $visa
                    singleGroundPackage: $singleGroundPackage
                    singleSupp: $singleSupp
                    singleAirticket: $singleAirticket
                    singleTipping: $singleTipping
                    singleInsurance: $singleInsurance
                    singleVisa: $singleVisa
                    childGroundPackage: $childGroundPackage
                    childSupp: $childSupp
                    childAirticket: $childAirticket
                    childTipping: $childTipping
                    childInsurance: $childInsurance
                    childVisa: $childVisa
                    childNoBedGroundPackage: $childNoBedGroundPackage
                    childNoBedSupp: $childNoBedSupp
                    childNoBedAirticket: $childNoBedAirticket
                    childNoBedTipping: $childNoBedTipping
                    childNoBedInsurance: $childNoBedInsurance
                    childNoBedVisa: $childNoBedVisa
                    ground: $ground
                }
            ) {
                _id
                status
            }
        }
    `,
    UPDATE_AMENITIES: gql`
        mutation UpdateAmenities(
            $_id: ID!
            $meals: AmenitiesTypeEnum
            $insurance: AmenitiesTypeEnum
            $wifi: AmenitiesTypeEnum
            $group: AmenitiesTypeEnum
            $tax: AmenitiesTypeEnum
            $checkin: AmenitiesTypeEnum
            $tourleader: AmenitiesTypeEnum
            $hotel: AmenitiesTypeEnum
            $gratuties: AmenitiesTypeEnum
            $fullpayment: AmenitiesTypeEnum
            $accessible: AmenitiesTypeEnum
            $facilities: AmenitiesTypeEnum
            $pool: AmenitiesTypeEnum
            $driver: AmenitiesTypeEnum
            $allin: AmenitiesTypeEnum
        ) {
            updateAmenities(
                input: {
                    _id: $_id
                    meals: $meals
                    insurance: $insurance
                    wifi: $wifi
                    group: $group
                    tax: $tax
                    checkin: $checkin
                    tourleader: $tourleader
                    hotel: $hotel
                    gratuties: $gratuties
                    fullpayment: $fullpayment
                    accessible: $accessible
                    facilities: $facilities
                    pool: $pool
                    driver: $driver
                    allin: $allin
                }
            ) {
                _id
                status
            }
        }
    `,
    CREATE_USER: gql`
        mutation createUser(
            $name: String!
            $username: String!
            $password: String!
            $phone: String!
            $email: String!
            $address: String!
            $city: String!
            $state: String!
            $postal: String!
            $country: String!
        ) {
            createUser(
                input: {
                    name: $name
                    username: $username
                    password: $password
                    phone: $phone
                    email: $email
                    address: $address
                    city: $city
                    state: $state
                    postal: $postal
                    country: $country
                }
            ) {
                _id
            }
        }
    `,
    CREATE_PAYMENT_VOUCHER: gql`
        mutation createPaymentVoucher(
            $payee: ID!
            $method: MethodTypeEnum!
            $date: String!
            $batch: String!
            $orderNo: String!
            $address: String!
            $amount: Float!
            $address2: String
            $amount2: Float
            $address3: String
            $amount3: Float
            $address4: String
            $amount4: Float
            $address5: String
            $amount5: Float
            $fastComm: Float!
            $calculatePoints: PaymentVoucherCalculatePointsEnum!
        ) {
            createPaymentVoucher(
                input: {
                    payee: $payee
                    method: $method
                    date: $date
                    batch: $batch
                    status: valid
                    calculatePoints: $calculatePoints
                    orderNo: $orderNo
                    item: { address: $address, amount: $amount }
                    item2: { address: $address2, amount: $amount2 }
                    item3: { address: $address3, amount: $amount3 }
                    item4: { address: $address4, amount: $amount4 }
                    item5: { address: $address5, amount: $amount5 }
                    fastComm: $fastComm
                }
            ) {
                _id
            }
        }
    `,
    FETCH_PAYMENT_VOUCHERS: gql`
        query FetchPaymentVouchers(
            $limit: Int!
            $page: Int!
            $status: String
            $calculatePoints: String
            $payee: String
            $batch: String
            $dateFrom: String
            $dateTo: String
        ) {
            fetchPaymentVouchers(
                filters: {
                    payee: $payee
                    batch: $batch
                    status: $status
                    calculatePoints: $calculatePoints
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                }
                pagination: { limit: $limit, page: $page }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    payee {
                        _id
                        name
                        nickname
                        code
                    }
                    date
                    method
                    batch
                    status
                    calculatePoints
                    orderNo
                    item {
                        amount
                        address
                    }
                    item2 {
                        amount
                        address
                    }
                    item3 {
                        amount
                        address
                    }
                    item4 {
                        amount
                        address
                    }
                    item5 {
                        amount
                        address
                    }
                    fastComm
                    createdBy {
                        _id
                        name
                    }
                }
                totals {
                    totalVoucherAmount
                }
            }
        }
    `,
    FETCH_VOUCHERS_SUMMARY: gql`
        query FetchVouchersSummary(
            $limit: Int!
            $page: Int!
            $status: String
            $payee: String
            $batch: String
            $dateFrom: String
            $dateTo: String
        ) {
            fetchVouchersSummary(
                filters: {
                    payee: $payee
                    batch: $batch
                    status: $status
                    dateFrom: $dateFrom
                    dateTo: $dateTo
                }
                pagination: { limit: $limit, page: $page }
            ) {
                pagination {
                    page
                    limit
                    documents
                    totalPages
                }
                data {
                    payee {
                        _id
                        name
                        nickname
                        code
                    }
                    amount
                }
            }
        }
    `,
    UPDATE_PAYMENT_VOUCHER_STATUS: gql`
        mutation UpdatePaymentVoucherStatus(
            $id: ID!
            $status: PaymentVoucherStatusTypeEnum!
        ) {
            updatePaymentVoucherStatus(input: { id: $id, status: $status }) {
                _id
            }
        }
    `,
    UPDATE_PAYMENT_VOUCHER_COMMISSION: gql`
        mutation UpdatePaymentVoucherCommission($id: ID!, $fastComm: Float!) {
            updatePaymentVoucherCommission(
                input: { id: $id, fastComm: $fastComm }
            ) {
                _id
            }
        }
    `,
    UPDATE_PAYMENT_VOUCHER_CALCULATE_POINTS: gql`
        mutation UpdatePaymentVoucherCalculatePoints(
            $id: ID!
            $calculatePoints: PaymentVoucherCalculatePointsEnum!
        ) {
            updateCalculatePointsStatus(
                input: { id: $id, calculatePoints: $calculatePoints }
            ) {
                _id
            }
        }
    `,
    FETCH_VIDEOS: gql`
        query FetchVideos($limit: Int!, $page: Int!) {
            fetchVideos(pagination: { limit: $limit, page: $page }) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    name
                    url
                }
            }
        }
    `,
    CREATE_VIDEO: gql`
        mutation CreateVideo($name: String!, $url: String!) {
            createVideo(input: { name: $name, url: $url }) {
                _id
            }
        }
    `,
    EDIT_VIDEO: gql`
        mutation UpdateVideo($_id: ID!, $name: String!, $url: String!) {
            updateVideo(input: { _id: $_id, name: $name, url: $url }) {
                _id
            }
        }
    `,
    DELETE_VIDEO: gql`
        mutation DeleteVideo($_id: ID!) {
            deleteVideo(input: { _id: $_id }) {
                _id
            }
        }
    `,
    FETCH_BANNERS: gql`
        query FetchBanners(
            $limit: Int!
            $page: Int!
            $type: String!
            $state: String!
        ) {
            fetchBanners(
                filter: { type: $type, state: $state }
                pagination: { limit: $limit, page: $page }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    name
                    url
                    type
                    position
                    state
                }
            }
        }
    `,
    CREATE_BANNER: gql`
        mutation CreateBanner(
            $name: String!
            $url: String!
            $state: String!
            $position: Int!
            $type: BannerTypeEnum!
        ) {
            createBanner(
                input: {
                    name: $name
                    url: $url
                    type: $type
                    position: $position
                    state: $state
                }
            ) {
                _id
            }
        }
    `,
    EDIT_BANNER: gql`
        mutation UpdateBanner(
            $_id: ID!
            $name: String!
            $position: Int!
            $url: String!
            $type: BannerTypeEnum!
        ) {
            updateBanner(
                input: {
                    _id: $_id
                    name: $name
                    url: $url
                    type: $type
                    position: $position
                }
            ) {
                _id
            }
        }
    `,
    FETCH_ALL_AREAS: gql`
        query FetchAllAreas {
            fetchAllAreas {
                _id
                name
            }
        }
    `,
    FETCH_ALL_COUNTRIES: gql`
        query FetchAllCountries {
            fetchAllCountries {
                _id
                name
            }
        }
    `,

    FETCH_ALL_STATES: gql`
        query FetchAllStates($country: String) {
            fetchAllState(filter: { country: $country }) {
                _id
                name
            }
        }
    `,
    FETCH_AREAS: gql`
        query FetchAreas($limit: Int!, $page: Int!) {
            fetchAreas(pagination: { limit: $limit, page: $page }) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    name
                }
            }
        }
    `,
    CREATE_AREA: gql`
        mutation CreateArea($name: String!) {
            createArea(input: { name: $name }) {
                _id
            }
        }
    `,
    EDIT_AREA: gql`
        mutation UpdateArea($_id: ID!, $name: String!) {
            updateArea(input: { _id: $_id, name: $name }) {
                _id
            }
        }
    `,
    FETCH_COUNTRY: gql`
        query FetchCountry {
            fetchCountry {
                _id
                name
                area {
                    name
                    _id
                }
            }
        }
    `,
    CREATE_COUNTRY: gql`
        mutation CreateCountry($name: String!, $area: String!) {
            createCountry(input: { name: $name, area: $area }) {
                _id
            }
        }
    `,
    EDIT_COUNTRY: gql`
        mutation UpdateCountry($_id: ID!, $name: String!) {
            updateCountry(input: { _id: $_id, name: $name }) {
                _id
            }
        }
    `,
    FETCH_AIRLINE: gql`
        query FetchAirline {
            fetchAirlines {
                _id
                name
                img
            }
        }
    `,
    CREATE_AIRLINE: gql`
        mutation CreateAirline($name: String!, $img: String!) {
            createAirline(input: { name: $name, img: $img }) {
                _id
            }
        }
    `,
    EDIT_AIRLINE: gql`
        mutation UpdateAirline($_id: ID!, $name: String!, $img: String!) {
            updateAirline(input: { _id: $_id, name: $name, img: $img }) {
                _id
            }
        }
    `,
    DELETE_AIRLINE: gql`
        mutation DeleteAirline($_id: ID!) {
            deleteAirline(input: { _id: $_id }) {
                _id
            }
        }
    `,
    FETCH_AIRPORTS: gql`
        query FetchAirports {
            fetchAirports {
                _id
                label
                value
            }
        }
    `,
    CREATE_AIRPORT: gql`
        mutation CreateAirport($label: String!, $value: String!) {
            createAirport(input: { label: $label, value: $value }) {
                _id
            }
        }
    `,
    EDIT_AIRPORT: gql`
        mutation UpdateAirport($_id: ID!, $label: String!, $value: String!) {
            updateAirport(input: { _id: $_id, label: $label, value: $value }) {
                _id
            }
        }
    `,
    DELETE_AIRPORT: gql`
        mutation DeleteAirport($_id: ID!) {
            deleteAirport(input: { _id: $_id }) {
                _id
            }
        }
    `,
    FETCH_STATES: gql`
        query FetchState($limit: Int!, $page: Int!, $country: String) {
            fetchStates(
                filter: { country: $country }
                pagination: { limit: $limit, page: $page }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    name
                    country {
                        name
                        _id
                    }
                }
            }
        }
    `,
    CREATE_STATE: gql`
        mutation CreateState($name: String!, $country: String!) {
            createState(input: { name: $name, country: $country }) {
                _id
            }
        }
    `,
    EDIT_STATE: gql`
        mutation UpdateState($_id: ID!, $name: String!) {
            updateState(input: { _id: $_id, name: $name }) {
                _id
            }
        }
    `,
    FETCH_CAMPAIGNS: gql`
        query FetchCampaigns($limit: Int!, $page: Int!, $type: String!) {
            fetchCampaigns(
                filter: { type: $type }
                pagination: { limit: $limit, page: $page }
            ) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    name
                    position
                    url
                    type
                    cn_notes
                    en_notes
                }
            }
        }
    `,
    CREATE_CAMPAIGN: gql`
        mutation CreateCampaign(
            $name: String!
            $url: String!
            $type: CampaignTypeEnum!
            $en_notes: String
            $cn_notes: String
            $position: Int!
        ) {
            createCampaign(
                input: {
                    name: $name
                    url: $url
                    position: $position
                    type: $type
                    cn_notes: $cn_notes
                    en_notes: $en_notes
                }
            ) {
                _id
            }
        }
    `,
    EDIT_CAMPAIGN: gql`
        mutation UpdateCampaign(
            $_id: ID!
            $name: String!
            $position: Int!
            $url: String!
            $type: CampaignTypeEnum!
            $en_notes: String
            $cn_notes: String
        ) {
            updateCampaign(
                input: {
                    _id: $_id
                    name: $name
                    url: $url
                    type: $type
                    cn_notes: $cn_notes
                    en_notes: $en_notes
                    position: $position
                }
            ) {
                _id
            }
        }
    `,
    DELETE_CAMPAIGN: gql`
        mutation DeleteCampaign($_id: ID!) {
            deleteCampaign(input: { _id: $_id }) {
                _id
            }
        }
    `,
    FETCH_EVENTS: gql`
        query FetchEvents($limit: Int!, $page: Int!) {
            fetchEvents(pagination: { limit: $limit, page: $page }) {
                pagination {
                    documents
                    totalPages
                    page
                    limit
                }
                data {
                    _id
                    start
                    end
                    title
                    start_time
                    end_time
                    location
                    image
                }
            }
        }
    `,
    FETCH_PRIME_SALES_REPORT: gql`
        query FetchPrimeSalesReport {
            fetchPrimeSalesReport {
                data {
                    user
                    personalBookingSales2021
                    convertUnit2021
                    convertPercentage2021
                    personalNetGdv2021
                    convertNetGdv2021
                    netGdvPercentage2021
                    groupBookingSales2021
                    groupConvertUnit2021
                    groupConvertPercentage2021
                    groupPersonalNetGdv2021
                    groupConvertNetGdv2021
                    groupNetGdvPercentage2021
                    personalBookingSales2022
                    convertUnit2022
                    convertPercentage2022
                    personalNetGdv2022
                    convertNetGdv2022
                    netGdvPercentage2022
                    groupBookingSales2022
                    groupConvertUnit2022
                    groupConvertPercentage2022
                    groupPersonalNetGdv2022
                    groupConvertNetGdv2022
                    groupNetGdvPercentage2022
                    personalBookingSales2023
                    convertUnit2023
                    convertPercentage2023
                    personalNetGdv2023
                    convertNetGdv2023
                    netGdvPercentage2023
                    groupBookingSales2023
                    groupConvertUnit2023
                    groupConvertPercentage2023
                    groupPersonalNetGdv2023
                    groupConvertNetGdv2023
                    groupNetGdvPercentage2023
                    growthPersonalSales22
                    growthPersonalNetGdv22
                    growthConvertUnit22
                    growthConvertNetGdv22
                    growthGroupSales22
                    growthGroupNetGdv22
                    growthGroupConvertUnit22
                    growthGroupConvertNetGdv22
                    growthPersonalSales23
                    growthPersonalNetGdv23
                    growthConvertUnit23
                    growthConvertNetGdv23
                    growthGroupSales23
                    growthGroupNetGdv23
                    growthGroupConvertUnit23
                    growthGroupConvertNetGdv23
                }
            }
        }
    `,
    FETCH_MONTHLY_SALES_REPORT: gql`
        query FetchMonthlySalesReport($user: ID) {
            fetchMonthlySalesReport(filter: { user: $user }) {
                data {
                    date
                    personalBookingSales
                    convertUnit
                    cancelledUnit
                    convertPercentage
                    personalNetGdv
                    convertNetGdv
                    cancelledNetGdv
                    netGdvPercentage
                    groupBookingSales
                    groupConvertUnit
                    groupConvertPercentage
                    groupPersonalNetGdv
                    groupConvertNetGdv
                    groupNetGdvPercentage
                    groupCancelledUnit
                    groupCancelledNetGdv
                }
            }
        }
    `,
    CREATE_EVENT: gql`
        mutation CreateEvent(
            $start: String!
            $end: String!
            $title: String!
            $start_time: String
            $end_time: String
            $location: String!
            $image: String
        ) {
            createEvent(
                input: {
                    start: $start
                    end: $end
                    title: $title
                    start_time: $start_time
                    end_time: $end_time
                    location: $location
                    image: $image
                }
            ) {
                _id
            }
        }
    `,
    EDIT_EVENT: gql`
        mutation EditEvent(
            $_id: ID!
            $start: String!
            $end: String!
            $title: String!
            $start_time: String
            $end_time: String
            $location: String!
            $image: String
        ) {
            updateEvent(
                input: {
                    _id: $_id
                    start: $start
                    end: $end
                    title: $title
                    start_time: $start_time
                    end_time: $end_time
                    location: $location
                    image: $image
                }
            ) {
                _id
            }
        }
    `,
    DELETE_EVENT: gql`
        mutation DeleteEvent($_id: ID!) {
            deleteEvent(input: { _id: $_id }) {
                _id
            }
        }
    `,
};
